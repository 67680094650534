import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './Modal.module.postcss';

class Modal extends Component {
  render() {
    return (
      <div className={styles.ModalContainer}>
        <div
          className={styles.ModalContent + ' ' + this.props.contentClassName}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
};

export default Modal;
